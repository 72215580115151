import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { DataSetService } from './data-set.service';

@Injectable({
  providedIn: 'root'
})
export class DataSetContentResolver implements Resolve<any> {

  constructor(private dataSetService: DataSetService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.dataSetService.getContentOfSchema(route.params.id);
  }
}
