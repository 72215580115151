import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../../../login/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private loginService: LoginService,
              private router: Router) { }

  onLogout() {
    LoginService.logout();
    this.router.navigate(['/login'])
      .then(() => console.log('Navigation complete'))
      .catch(error => console.log(error));
  }

  get isAuthenticated() {
    return this.loginService.isAuthenticated();
  }

  ngOnInit() {
  }

}
