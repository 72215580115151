import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { DictionaryService } from '../dictionary/dictionary.service';

@Injectable({
  providedIn: 'root'
})
export class SchemaResolver implements Resolve<any> {

  constructor(private dashboardService: DictionaryService) { }

  resolve() {
    return this.dashboardService.getDictionaryList();
  }
}
