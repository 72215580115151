import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient) { }

  getAll(order, sort, pageIndex, pageSize) {
    return this.httpClient
      .get<any>(`${environment.baseURL}api/jira-source/list?page=${pageIndex}&count=${pageSize}&order=${order.toUpperCase()}&sort=${sort || 'id'}`) // tslint:disable:max-line-length
      .pipe(dataSourceList => dataSourceList);
  }

  getById(id) {
    return this.httpClient.get<any>(`${environment.baseURL}api/jira-source/${id}`)
      .pipe(dataSource => dataSource);
  }

  create(dataSourceData) {
    return this.httpClient.post<any>(`${environment.baseURL}api/jira-source`, dataSourceData)
      .pipe(dataSource => dataSource);
  }

  update(dataSourceData) {
    return this.httpClient.put<any>(`${environment.baseURL}api/jira-source`, dataSourceData)
      .pipe(dataSource => dataSource);
  }

  delete(id) {
    return this.httpClient.delete<any>(`${environment.baseURL}api/jira-source/${id}`)
      .pipe(response => response);
  }

  manualUpdate(id) {
    return this.httpClient.get<any>(`${environment.baseURL}api/jira-issue/jira-source/${id}`)
      .pipe(response => response);
  }

  manualUpdateLatest(id) {
    return this.httpClient.get<any>(`${environment.baseURL}api/jira-issue/jira-source/latest/${id}`)
      .pipe(response => response);
  }

  cleanDataSource(id) {
    return this.httpClient.delete<any>(`${environment.baseURL}api/jira-issue/jira-source/${id}`)
      .pipe(response => response);
  }

  checkMaintenanceMode(id) {
    return this.httpClient.get<any>(`${environment.baseURL}api/jira-source/mode/${id}`)
      .pipe(response => response);
  }
}
