import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

import { DialogData } from '../../models/dialog-data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DictionaryService } from '../../../dictionary/dictionary.service';

@Component({
  selector: 'app-dialog-schema',
  templateUrl: './dialog-schema.component.html',
  styleUrls: ['./dialog-schema.component.scss']
})
export class DialogSchemaComponent implements OnInit {
  name: string;
  type: string;
  mode: string;
  description: string;
  schemaForm: FormGroup;
  fieldTypes = [
    {
      value: 'INTEGER',
      viewValue: 'INTEGER'
    },
    {
      value: 'STRING',
      viewValue: 'STRING'
    },
    {
      value: 'DATETIME',
      viewValue: 'DATETIME'
    },
    {
      value: 'RECORD',
      viewValue: 'RECORD'
    },
    {
      value: 'BYTES',
      viewValue: 'BYTES'
    },
    {
      value: 'FLOAT',
      viewValue: 'FLOAT'
    },
    {
      value: 'NUMERIC',
      viewValue: 'NUMERIC',
    },
    {
      value: 'BOOLEAN',
      viewValue: 'BOOLEAN'
    },
    {
      value: 'TIMESTAMP',
      viewValue: 'TIMESTAMP'
    },
    {
      value: 'DATE',
      viewValue: 'DATE'
    },
    {
      value: 'TIME',
      viewValue: 'TIME'
    },
    {
      value: 'GEOGRAPHY',
      viewValue: 'GEOGRAPHY'
    }
  ];
  modeTypes = [
    {
      value: 'REQUIRED',
      viewValue: 'REQUIRED'
    },
    {
      value: 'NULLABLE',
      viewValue: 'NULLABLE'
    },
    {
      value: 'REPEATED',
      viewValue: 'REPEATED'
    }
  ];

  constructor(public dialogRef: MatDialogRef<DialogSchemaComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private dictionaryService: DictionaryService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.schemaForm.invalid) {
      return;
    }

    this.name = this.schemaForm.value.name;
    this.type = this.schemaForm.value.type;
    this.mode = this.schemaForm.value.mode;

    if (this.data.action === 'new') {
      this.data.dataSource = {
        name: this.name,
        type: this.type,
        mode: this.mode
      };
      this.dialogRef.close(this.data);
      this.openSnackBar('Schema created', 'Close');
    }
    if (this.data.action === 'edit') {
      this.data.dataSource = {
        name: this.name,
        type: this.type,
        mode: this.mode
      };
      this.dialogRef.close(this.data);
      this.openSnackBar('Schema updated', 'Close');
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  ngOnInit() {
    this.schemaForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      mode: ['', Validators.required],
      description: ['']
    });
    if (this.data.action === 'edit') {
      this.schemaForm.patchValue(this.data.dataSource);
    }
  }

}
