import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IssueListService {

  constructor(private httpClient: HttpClient) { }

  getIssueList(id) {
    return this.httpClient
      .get<any>(`${environment.baseURL}api/jira-issue/jira-source/${id}`)
      .pipe(issueList => issueList);
  }
}
