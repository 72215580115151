import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { environment } from '../environments/environment';
import { LoginService } from './login/login.service';

@Injectable({
  providedIn: 'root'
})

export class JwtInterceptor implements HttpInterceptor {
  isMessageDisplayed = false;

  constructor(private loginService: LoginService,
              private router: Router,
              private snackBar: MatSnackBar) { }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  // intercept request and add token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            if (event.body.error && event.body.error === 'Authorization the token is not valid' ||
              event.body.error && event.body.error === 'Authorization Not valid token') {
              LoginService.logout();
              this.router.navigate(['login']);
              this.openSnackBar(event.body.error, 'Close');
            }
          }
        }, error => {
          if (error.status === 401) {
            LoginService.logout();
            this.router.navigate(['login']);
            if (error.error && error.error.error && !this.isMessageDisplayed) {
              this.openSnackBar(error.error.error, 'Close');
              this.isMessageDisplayed = true;
            }
          }
          if (error.error.errors && error.error.errors.length > 0) {
            const errors = error.error.errors;
            for (let i = 0; i < errors.length; i++) {
              if (errors[i].code === 403) {
                if (!errors[i].description) {
                  this.openSnackBar(errors[i].message, 'Close');
                  if (this.loginService.isAuthenticated()) {
                    this.router.navigate(['dashboard']);
                  } else {
                    this.router.navigate(['login']);
                  }
                }
              }
            }
          }
          // http response status code
          if (!environment.production) {
            console.log('----response----');
            console.error('status code:');
            console.error(error.status);
            console.error(error.message);
            console.log('--- end of response---');
          }
        })
      );
  }
}
