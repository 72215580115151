import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { DashboardService } from './dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardResolver implements Resolve<any> {

  constructor(private dashboardService: DashboardService) { }

  resolve() {
    return this.dashboardService.getAll('ASC', 'id', 0, 5);
  }
}
