import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';

import {DialogData} from '../../models/dialog-data';
import {DashboardService} from '../../../dashboard/dashboard.service';


@Component({
  selector: 'app-dialog-data-source',
  templateUrl: './dialog-data-source.component.html',
  styleUrls: ['./dialog-data-source.component.scss']
})
export class DialogDataSourceComponent implements OnInit {
  url: string;
  login: string;
  password: string;
  useCookiesBasedAuthentication: boolean;
  token: string;
  jql: string;
  passwordSecurity: boolean;
  tempoPluginToken: string;
  tokensCache: object = {};
  dataSourceForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<DialogDataSourceComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private dashboardService: DashboardService) { }

  get f() { return this.dataSourceForm.controls; }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.dataSourceForm.invalid) {
      return;
    }

    this.url = this.dataSourceForm.value.url;
    this.login = this.dataSourceForm.value.login;
    this.password = this.dataSourceForm.value.password;
    this.useCookiesBasedAuthentication = this.dataSourceForm.value.useCookiesBasedAuthentication == true;
    this.jql = this.dataSourceForm.value.jql;
    this.passwordSecurity = this.dataSourceForm.value.passwordSecurity;
    this.tempoPluginToken = this.dataSourceForm.value.tempoPluginToken;

    if (this.passwordSecurity) {
      this.token = '';
    } else {
      const tokenCount = this.login.split(/[\s,;]+/)
        .filter((login) => !!login)
        .length;

      if (tokenCount === 1) {
        this.token = this.dataSourceForm.value.tokens[0].token;
      } else {
        this.token = this.dataSourceForm.value.tokens.map((item) => item.login + ':' + item.token).join(', ');
      }
    }

    if (this.data.action === 'new') {
      this.dashboardService.create({
        login: this.login,
        password: this.password,
        useCookiesBasedAuthentication: this.useCookiesBasedAuthentication,
        token: this.token,
        url: this.url,
        jql: this.jql,
        passwordSecurity: this.passwordSecurity,
        tempoPluginToken: this.tempoPluginToken
      }).subscribe((data) => {
        this.data.dataSource = data;
        this.dialogRef.close(this.data);
        this.openSnackBar('Data source created', 'Close');
      }, (error) => {
        if (error.error && error.error.errors.length) {
          error.error.errors.forEach(e => {
            this.openSnackBar(e.message, 'Close');
          });
        }
      });
    }

    if (this.data.action === 'edit') {
      this.dashboardService.update({
        id: this.data.dataSource.id,
        login: this.login,
        password: this.password,
        useCookiesBasedAuthentication: this.useCookiesBasedAuthentication,
        token: this.token,
        url: this.url,
        jql: this.jql,
        passwordSecurity: this.passwordSecurity,
        tempoPluginToken: this.tempoPluginToken
      }).subscribe((data) => {
        this.data.dataSource = data;
        this.dialogRef.close(this.data);
        this.openSnackBar('Data source updated', 'Close');
      }, (error) => {
        if (error.error && error.error.errors.length) {
          error.error.errors.forEach(e => {
            this.openSnackBar(e.message, 'Close');
          });
        }
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }

  ngOnInit() {
    const ds = this.data.dataSource;
    const isPasswordOrTokenRequired = this.data.action === 'new';

    this.dataSourceForm = this.formBuilder.group({
      passwordSecurity: [true],
      url: ['', Validators.required],
      login: ['', Validators.required],
      password: isPasswordOrTokenRequired ? ['', Validators.required] : [''],
      useCookiesBasedAuthentication: [false],
      tokens: this.formBuilder.array(ds && ds.passwordSecurity === false ? this.createTokenItems(ds.login, isPasswordOrTokenRequired) : []),
      jql: ['', Validators.required],
      tempoPluginToken: ['']
    });

    if (this.data.action === 'edit') {
      this.dataSourceForm.patchValue(ds);
      this.dataSourceForm.patchValue({tokens: this.extractTokens(ds.login, ds.token)});
      if (!ds.passwordSecurity) {
        this.dataSourceForm.controls['password'].setValidators(null);
        this.dataSourceForm.controls['password'].disable();
      }
    }

    this.dataSourceForm.controls['passwordSecurity'].valueChanges.subscribe((newValue) => {
      const password = this.dataSourceForm.controls['password'];

      if (newValue === true) {
        password.enable();
        password.setValidators(Validators.required);
        this.disableTokenControls();
        this.tokensCache = [];
      } else {
        password.disable();
        password.setValue(null);
        password.setValidators(null);
        this.enableTokenControls(isPasswordOrTokenRequired);
      }
    });

    this.dataSourceForm.controls['login'].valueChanges.subscribe(() => {
      this.enableTokenControls(isPasswordOrTokenRequired);
    });
  }

  createTokenItems(logins, isPasswordOrTokenRequired): any[] {
    const items = [];

    if (logins) {
      for (let login of this.splitValues(logins, true)) {
        const item = this.createTokenItem(login, isPasswordOrTokenRequired);

        item.valueChanges.subscribe((newValue) => {
          this.tokensCache[newValue.login.toLowerCase()] = newValue.token;
        });

        items.push(item);
      }
    }

    return items;
  }

  createTokenItem(login, isPasswordOrTokenRequired): any {
    const validator = isPasswordOrTokenRequired ? Validators.required : null;

    return this.formBuilder.group({
      login: [login],
      token: [this.tokensCache[login.toLowerCase()] || '', validator]
    });
  }

  enableTokenControls(isPasswordOrTokenRequired) {
    this.disableTokenControls();

    const tokens = this.dataSourceForm.controls['tokens'] as FormArray;
    for (let item of this.createTokenItems(this.dataSourceForm.controls['login'].value, isPasswordOrTokenRequired)) {
      tokens.push(item);
    }
  }

  disableTokenControls() {
    const tokens = this.dataSourceForm.controls['tokens'] as FormArray;

    while (tokens.length) {
      tokens.removeAt(0);
    }
  }

  extractTokens(login, tokenStr) {
    const logins = this.splitValues(login, true);
    const tokensMap = this.extractTokensMap(tokenStr)
    const tokens = [];

    if (tokensMap) {
      for (let login of logins) {
        tokens.push({
          login: login,
          token: tokensMap[login] || ''
        });
      }
    } else if (logins.length == 1) {
      tokens.push({
        login: logins[0],
        token: tokenStr
      });
    }

    return tokens;
  }

  extractTokensMap(tokenStr) {
    const pairs = this.splitValues(tokenStr, false);
    const map = {};
    let hasPairs = false

    for (let pair of pairs) {
      let pos = pair.indexOf(':');
      if (pos > 0) {
        let login = pair.substring(0, pos).toLowerCase();
        map[login] = pair.substring(pos + 1);
        hasPairs = true
      }
    }

    if (hasPairs) {
      return map;
    } else {
      return null;
    }
  }

  splitValues(str, castToLowerCase) {
    const values = [];

    if (str) {
      for (let value of str.split(/[\s,;]+/)) {
        if (value) {
          if (castToLowerCase) {
            values.push(value.toLowerCase());
          } else {
            values.push(value);
          }
        }
      }
    }

    return values;
  }
}
