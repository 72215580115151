import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';

import { DialogData } from '../../models/dialog-data';
import { DictionaryService } from '../../../dictionary/dictionary.service';
import { DialogSchemaComponent } from '../dialog-schema/dialog-schema.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dialog-dictionary',
  templateUrl: './dialog-dictionary.component.html',
  styleUrls: ['./dialog-dictionary.component.scss']
})
export class DialogDictionaryComponent implements OnInit {
  name: string;
  schema: string;
  description: string;
  dictionaryForm: FormGroup;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['name', 'type', 'mode', 'action'];
  dataSource: any;

  constructor(public dialog: MatDialog,
              public dialogRef: MatDialogRef<DialogDictionaryComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private dictionaryService: DictionaryService,
              private router: Router) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if (this.dictionaryForm.invalid) {
      return;
    }

    this.name = this.dictionaryForm.value.name;
    this.schema = this.dataSource.data || [];

    this.dialogRef.close(this.data);
    this.router.navigate(['/schema', this.name]);

    // if (this.data.action === 'new') {
    //   const tableData = {
    //     tableName: this.name,
    //     tableSource: JSON.stringify(this.schema)
    //   };
    //   this.dictionaryService.createDictionary(tableData).subscribe(data => {
    //     console.log(data);
    //     this.data.dataSource = tableData;
    //     this.dialogRef.close(this.data);
    //     this.openSnackBar('Table created', 'Close');
    //   }, error => {
    //     if (error.error && error.error.errors.length) {
    //       error.error.errors.forEach(e => {
    //         this.openSnackBar(e.message, 'Close');
    //       });
    //     }
    //   });
    // }
    // if (this.data.action === 'edit') {
    //
    //   this.dialogRef.close(this.data);
    //   this.openSnackBar('Table updated', 'Close');
    // }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  addSchema(action, element?) {
    const dialogRef = this.dialog.open(DialogSchemaComponent, {
      data: {
        name: 'schema',
        action: action,
        dataSource: element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 'edit') {
        const index = this.dataSource.data.findIndex(e => e.name === result.dataSource.name);
        this.dataSource.data[index] = result.dataSource;
        this.dataSource = new MatTableDataSource(this.dataSource.data);
      }
      if (result && result.action === 'new') {
        this.dataSource.data.push(result.dataSource);
        this.dataSource = new MatTableDataSource(this.dataSource.data);
      }
    });
  }

  removeSchema(element) {
    const index = this.dataSource.data.findIndex(e => e.name === element.name);
    this.dataSource.data.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    this.openSnackBar('Schema removed', 'Close');
  }

  ngOnInit() {
    this.dictionaryForm = this.formBuilder.group({
      name: ['', Validators.required],
      schema: [''],
      description: ['']
    });
    if (this.data.action === 'edit') {
      this.dictionaryForm.patchValue(this.data.dataSource);
      this.dataSource = new MatTableDataSource([]);
    }
    if (this.data.action === 'new') {
      this.dataSource = new MatTableDataSource([]);
    }
    this.dataSource.sort = this.sort;
  }

}
