import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';
import { DashboardResolver } from './dashboard/dashboard.resolver';
import { IssueListResolver } from './issue-list/issue-list.resolver';
import { DictionaryResolver } from './dictionary/dictionary.resolver';
import { SchemaResolver } from './schema/schema.resolver';
import { DataSetResolver } from './data-set/data-set.resolver';
import { DataSetContentResolver } from './data-set/data-set-content.resolver';

const routes: Routes = [
  {
    path: 'data-set/:id',
    loadChildren: './data-set/data-set.module#DataSetModule',
    canActivate: [AuthGuard],
    resolve: {
      dataSources: DataSetResolver,
      schemaContent: DataSetContentResolver
    }
  },
  {
    path: 'schema/:id',
    loadChildren: './schema/schema.module#SchemaModule',
    canActivate: [AuthGuard],
    resolve: {
      dataSource: SchemaResolver
    }
  },
  {
    path: 'dictionary',
    loadChildren: './dictionary/dictionary.module#DictionaryModule',
    canActivate: [AuthGuard],
    resolve: {
      dataSources: DictionaryResolver
    }
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard],
    resolve: {
      dataSources: DashboardResolver
    }
  },
  {
    path: 'issue-list/:id',
    loadChildren: './issue-list/issue-list.module#IssueListModule',
    canActivate: [AuthGuard],
    resolve: {
      issueList: IssueListResolver
    }
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    DashboardResolver,
    IssueListResolver,
    DictionaryResolver,
    SchemaResolver,
    DataSetResolver,
    DataSetContentResolver
  ]
})
export class AppRoutingModule { }
