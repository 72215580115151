import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataSetService {

  constructor(private httpClient: HttpClient) { }

  getAll(tableName) {
    return this.httpClient.get<any>(`${environment.baseURL}api/big-query/table/schema?tableName=${tableName}`)
      .pipe(data => data);
  }

  save(tableDataSet) {
    return this.httpClient.post<any>(`${environment.baseURL}api/big-query/table/content`, tableDataSet)
      .pipe(data => data);
  }

  getContentOfSchema(tableName) {
    return this.httpClient.get<any>(`${environment.baseURL}api/big-query/table/content?tableName=${tableName}`)
      .pipe(data => data);
  }
}
