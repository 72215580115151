import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  constructor(private httpClient: HttpClient) { }

  getDictionaryList() {
    return this.httpClient.get<any>(`${environment.baseURL}api/big-query/table/list`)
      .pipe(map(data => {
        const list = [];
        data.forEach(e => {
          list.push({tableName: e, tableSource: '[]'});
        });
        return list;
      }));
  }

  getDictionaryById(id) {
    return this.httpClient.get<any>(`${environment.baseURL}/${id}`)
      .pipe(data => data);
  }

  createDictionary(dictionaryData) {
    return this.httpClient.post<any>(`${environment.baseURL}api/big-query/table`, dictionaryData)
      .pipe(data => data);
  }

  updateDictionary(dictionaryData) {
    return this.httpClient.put<any>(`${environment.baseURL}`, dictionaryData)
      .pipe(data => data);
  }

  deleteDictionary(name) {
    return this.httpClient.delete<any>(`${environment.baseURL}api/big-query/table?tableName=${name}`)
      .pipe(data => data);
  }

  getSchemaList() {
    return this.httpClient.get<any>(`${environment.baseURL}`)
      .pipe(data => data);
  }

  getSchemaById(id) {
    return this.httpClient.get<any>(`${environment.baseURL}/${id}`)
      .pipe(data => data);
  }

  createSchema(schemaData) {
    return this.httpClient.post<any>(`${environment.baseURL}`, schemaData)
      .pipe(data => data);
  }

  updateSchema(schemaData) {
    return this.httpClient.put<any>(`${environment.baseURL}`, schemaData)
      .pipe(data => data);
  }

  deleteSchema(id) {
    return this.httpClient.delete<any>(`${environment.baseURL}/${id}`)
      .pipe(data => data);
  }
}
