import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

import { DialogData } from '../../models/dialog-data';

@Component({
  selector: 'app-dialog-status-description',
  templateUrl: 'dialog-status-description.component.html',
  styleUrls: ['dialog-status-description.component.scss']
})
export class DialogStatusDescriptionComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }
}
