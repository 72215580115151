import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BroadcastMessageService {
  private storageSubject: Subject<Window>;

  static broadcastMessage(message: string, element: object) {
    localStorage.setItem(message, JSON.stringify(element));
  }

  static getStoredObject() {
    const m = localStorage.getItem('message');
    if (m) {
      return JSON.parse(m);
    }
    return {};
  }

  constructor(private eventManager: EventManager) {
    this.storageSubject = new Subject();
    this.eventManager.addGlobalEventListener('window', 'storage', this.onStorage.bind(this));
  }

  get onStorage$(): Observable<Window> {
    return this.storageSubject.asObservable();
  }

  private onStorage(event: StorageEvent) {
    this.storageSubject.next(<Window>event.target);
  }
}
