import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../shared/models/user';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public static updateToken(token: any): void {
    if (token && token.token) {
      localStorage.setItem('currentUser', JSON.stringify(token));
      localStorage.setItem('access_token', token.token);
    }
  }

  public static getCurrentUserDetails() {
    const userDetails = localStorage.getItem('currentUserData');

    if (!userDetails) {
      return null;
    } else {
      return JSON.parse(userDetails);
    }
  }

  public static logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
    localStorage.removeItem('currentUserData');
  }

  constructor(private httpClient: HttpClient,
              public jwtHelper: JwtHelperService) { }

  login(login: string, password: string) {
    return this.httpClient.post<any>(`${environment.baseURL}auth/generate-token`, { login, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('access_token', user.token);
        }

        return user;
      }));
  }

  isAdmin(token): boolean {
    if (!token) {
      return false;
    }
    // decode the token to get its payload
    const tokenPayload = this.jwtHelper.decodeToken(token);

    return tokenPayload.scopes === 'ROLE_ADMIN';
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');

    // Check whether the token is expired and return
    // true or false
    if (!token) {
      return false;
    }
    return !this.jwtHelper.isTokenExpired(token);
  }

  public getUserData() {
    const token = localStorage.getItem('access_token');

    if (!token) {
      return null;
    } else {
      return this.jwtHelper.decodeToken(token);
    }
  }
}
