import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './shared/modules/material.module';
import { HeaderComponent } from './shared/components/header/header.component';
import { DialogDataSourceComponent } from './shared/components/dialog-data-source/dialog-data-source.component';
import { JwtInterceptor } from './jwt.interceptor';
import { DialogDictionaryComponent } from './shared/components/dialog-dictionary/dialog-dictionary.component';
import { DialogSchemaComponent } from './shared/components/dialog-schema/dialog-schema.component';
import { BroadcastMessageService } from './shared/services/broadcast.message.service';
import {DialogStatusDescriptionComponent} from "./shared/components/dialog-status-description/dialog-status-description.component";

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DialogDataSourceComponent,
    DialogDictionaryComponent,
    DialogSchemaComponent,
    DialogStatusDescriptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          'localhost:4200',
          'localhost:8080',
          'monday-new-233115.appspot.com',
          'frontend-dot-monday-new-233115.appspot.com',
          '95.216.33.105:28080',
          'monday-back:8080'
        ],
        blacklistedRoutes: ['localhost:3001/auth/']
      }
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    DialogDataSourceComponent,
    DialogDictionaryComponent,
    DialogSchemaComponent,
    DialogStatusDescriptionComponent
  ],
  providers: [
    JwtInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    BroadcastMessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
